import React, { useState } from "react"
import {
  Container,
  DropdownButton,
  Dropdown,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap"
import Layout from "../components/layout/Index"
import Title from "../components/commons/page/title/Index"
import { graphql } from "gatsby"
import Card from "../components/commons/card/agenda/index"
// import Pagination from "../components/commons/pagination/index"
import VerticalAds from "../components/commons/ads/vertical-ads/Index"
import { withTrans } from "../i18n/withTrans"
import { inRangeDate } from "../utils/date"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}))

const Index = ({ data, t, i18n }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [month, setMonth] = useState(0)
  const [searchTextTemp, setSearchTextTemp] = useState("")
  const [searchText, setSearchText] = useState("")
  const language = i18n.language
  const classes = useStyles()

  const dataFilter = data?.allWpKegiatan?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataAgenda =
    month === 0
      ? dataFilter
      : dataFilter.filter(item => {
          let start_date = item.event.startDate.split("/")
          let end_date = item.event.endDate.split("/")
          return (
            parseInt(start_date[1]) === month || parseInt(end_date[1]) === month
          )
        })

  const dataIklan = data?.allWpIklanGks?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataIklanGoldB = dataIklan?.filter(
    o => o.iklan.slotIklan === "Gold-B (280 x 450 px)"
  )

  // const lengthData = dataAgenda.length
  const lengthData = dataAgenda.filter(item =>
    item.title.toLowerCase().includes(searchText.toLowerCase())
  ).length

  const pageSize = 6
  const pagesCount = Math.ceil(lengthData / pageSize)
  const monthName = [
    t("agenda.allMonth"),
    t("agenda.january"),
    t("agenda.february"),
    t("agenda.march"),
    t("agenda.april"),
    t("agenda.may"),
    t("agenda.june"),
    t("agenda.july"),
    t("agenda.aug"),
    t("agenda.sep"),
    t("agenda.oct"),
    t("agenda.nov"),
    t("agenda.dec"),
  ]

  const handlePageClick = (e, i) => {
    setCurrentPage(i)
  }

  const handlePreviousClick = (e, currentPage) => {
    setCurrentPage(currentPage - 1)
  }

  const handleNextClick = (e, currentPage) => {
    setCurrentPage(currentPage + 1)
  }

  const onSearch = e => {
    e.preventDefault()
    setSearchText(searchTextTemp)
  }

  return (
    <Layout>
      <Container className="page-container article-container">
        <Row className="justify-content-between align-items-center">
          <Col lg={6} md={4} sm={12} xs={12} className="page-title m-0">
            <span>{t("header.agenda")}</span>
          </Col>
          <Col lg={6} md={8} sm={12} xs={12}>
            <Row className="justify-content-between">
              <Col
                md={8}
                sm={12}
                xs={12}
                className="d-flex justify-content-start mb-3"
              >
                <Form.Control
                  onChange={e => setSearchTextTemp(e.target.value)}
                  placeholder={t("article.enterKeyword")}
                />
                <Button type="submit" onClick={e => onSearch(e)}>
                  {/* {t("search")} */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </Button>
              </Col>
              <Col
                md={4}
                sm={12}
                xs={12}
                className="d-flex member-select justify-content-end mb-3"
              >
                <DropdownButton
                  title={`${monthName[month]}`}
                  variant="outline-primary"
                  style={{ width: "100%" }}
                >
                  {monthName.map((item, index) => (
                    <Dropdown.Item onClick={() => setMonth(index)}>
                      {item}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Title label="Agenda" />
        <Row>
          <Col md={9}>
            <Form className="mini-news-container ">
              <Form.Row>
                <Form.Control
                  onChange={e => setSearchTextTemp(e.target.value)}
                  placeholder={t("article.enterKeyword")}
                />
                <Button type="submit" onClick={e => onSearch(e)}>
                  {t("search")}
                </Button>
              </Form.Row>
            </Form>
          </Col>
          <Col md={3}>
            <div style={{ float: "right" }}>
              <DropdownButton
                title={`${monthName[month]}`}
                variant="outline-primary"
                // style={{ width: 120 }}
              >
                {monthName.map((item, index) => (
                  <Dropdown.Item onClick={() => setMonth(index)}>
                    {item}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col md={9} style={{ padding: 0 }}>
            <Row style={{ marginBottom: 20 }}>
              {dataAgenda.length > 0 ? (
                <>
                  {dataAgenda
                    .filter(item =>
                      item.title
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                    .map((item, i) => (
                      <Col md={4} style={{ padding: 0 }} key={i}>
                        <Card
                          date={item.event?.tanggalKegiatan?.tanggalMulai}
                          title={item?.title}
                          // total={item.event?.total}
                          image={item.event?.imgName?.sourceUrl}
                          slug={`/agenda/${item.slug}`}
                          key={item.id}
                        />
                      </Col>
                    ))}
                  {/* <div className="d-flex justify-content-center">
                    <Pagination
                      pagesCount={pagesCount}
                      currentPage={currentPage}
                      handlePageClick={handlePageClick}
                      handlePreviousClick={handlePreviousClick}
                      handleNextClick={handleNextClick}
                    />
                  </div> */}
                  <div
                    className={`${classes.root} d-flex justify-content-center`}
                  >
                    <Pagination
                      count={pagesCount}
                      page={currentPage}
                      onChange={handlePageClick}
                      shape="rounded"
                      color="primary"
                    />
                  </div>
                </>
              ) : (
                <Col className="mt-3">{t("noData")}</Col>
              )}
            </Row>
          </Col>
          <Col md={3} style={{ padding: 20 }}>
            {dataIklanGoldB?.length > 0 &&
            inRangeDate(
              dataIklanGoldB[0].iklan.tanggalJangkaIklanAwal,
              dataIklanGoldB[0].iklan.tanggalJangkaIklanAkhir
            ) &&
            dataIklanGoldB[0].iklan.uploadGambarIklanGoldB ? (
              <VerticalAds
                width={280}
                height={450}
                title="B. Gold AD SPACE"
                img_url={
                  dataIklanGoldB[0].iklan.uploadGambarIklanGoldB.sourceUrl
                }
              />
            ) : (
              <VerticalAds width={280} height={450} title="B. Gold AD SPACE" />
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpKegiatan {
      totalCount
      nodes {
        id
        slug
        language {
          id
          code
        }
        title
        event {
          tanggalKegiatan {
            tanggalMulai
            tanggalBerakhir
          }
          tanggalRegistrasi {
            tanggalMulai
            tanggalBerakhir
          }
          regionEvent
          imgName {
            sourceUrl
          }
          activityAddress
          description
          kontakPerson {
            nama
            noTelepon
          }
        }
      }
    }
    allWpIklanGks(sort: { fields: date, order: DESC }) {
      nodes {
        title
        iklan {
          slotIklan
          deskripsiIklan
          letakIklan {
            bronzeAdSpace
            goldA
            premium
            silverAdSpace
            goldB
          }
          uploadGambarIklanBronzeAdSpace {
            sourceUrl
          }
          uploadGambarIklanGoldA {
            sourceUrl
          }
          uploadGambarIklanGoldB {
            sourceUrl
          }
          uploadGambarIklanPremium {
            sourceUrl
          }
          uploadGambarIklanSilverAdSpace {
            sourceUrl
          }
          tanggalJangkaIklanAkhir
          tanggalJangkaIklanAwal
        }
        date
        language {
          code
        }
      }
    }
  }
`

export default withTrans(Index)
